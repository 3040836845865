import React, { useRef } from "react";
import BackButton from "../BackButton/BackButton";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { translations } from "../../utils/translations/translations";
import { directionVariants } from "../../utils/directionOptions";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import AppButton from "../AppButton/AppButton";
import introImg from "../../images/intro_smile_0.svg";
import Popup from "../Popup/Popup";
import RemotePopup from "../RemotePopup/RemotePopup";
import {
  setRemoteHenchmens,
  setDirection,
  setRemoteData,
} from "../../redux/actions/actions";
import { setRemoteOpts, getHenchmenLink } from "../../utils/roboApi";
import { useNavigate } from "react-router-dom";
import "./Remote.css";
import { parseTimestamp } from "../../utils/helpers";

function Remote() {
  const navigate = useNavigate();
  const tg = window.Telegram.WebApp;
  const direction = useSelector((state) => state.direction);
  const language = useSelector((state) => state.language);
  const remoteData = useSelector((state) => state.remoteData);
  const henchmens = useSelector((state) => state.remoteHenchmens);
  const currentUser = useSelector((state) => state.currentUser);
  const [isRemotePopupHidden, setIsRemotePopupHidden] = React.useState(true);
  const [isRemoteUsersHidden, setIsRemoteUsersHidden] = React.useState(true);
  const [isSettingsChanged, setIsSettingsChanged] = React.useState(false);
  const [isTrafficLimited, setIsTrafficLimited] = React.useState(false);
  const [activeHenchmen, setActiveHenchmen] = React.useState(
    remoteData?.data?.henchmens[0]?.id
  );
  const [activeHenchmenIndex, setActiveHenchmenIndex] = React.useState(0);
  const [doneMessageShow, setDoneMessageShow] = React.useState(false);
  const [doneMessage, setDoneMessage] = React.useState();
  const [nameChanged, setNamechanged] = React.useState(
    remoteData?.data?.henchmens[activeHenchmenIndex]?.description
  );
  const [trafficChanged, setTrafficChanged] = React.useState(
    remoteData?.data?.henchmens[activeHenchmenIndex]?.trafficLimit
  );
  const isRemoteInProgress =
    currentUser.owner === 1 &&
    (!remoteData.data || remoteData?.data?.henchmens?.length === 0);
  const today = new Date();
  const isTested = 0;
  const isNeverOwn = currentUser.owner === 0 && currentUser.own_tested === 0;
  const isTestOwnTariff =
    currentUser.own_tested === 1 &&
    (remoteData?.data?.tariff === "ownme_test" ||
      remoteData?.data?.tariff === "ownfam_test" ||
      remoteData?.data?.tariff === "owncom_test");
  const isOwnTariff =
    currentUser.owner === 1 &&
    remoteData?.data?.tariff !== "ownme_test" &&
    remoteData?.data?.tariff !== "ownfam_test" &&
    remoteData?.data?.tariff !== "owncom_test";
  // const isRemoteInProgress = true;

  const totalHechmensTraffic = remoteData?.data?.hechmens?.reduce(
    (acc, hechman) => acc + hechman.trafficLimit,
    0
  );
  const tariffType = remoteData?.data?.tariff?.replace(/[^a-zA-Z]/g, "");
  const tariffMonths = remoteData?.data?.tariff?.replace(/[a-zA-Z_]/g, "");
  const getMonthOrTest =
    remoteData?.data?.tariff?.includes("_1") ||
    remoteData?.data?.tariff?.includes("_test");

  const getOwnTariffTypeGb = () => {
    if (tariffType === "ownme") {
      return 500;
    }
    if (tariffType === "ownfam") {
      return 1000;
    }

    if (tariffType === "owncom") {
      return 2000;
    }
    if (remoteData?.data?.tariff?.includes("_test")) {
      return 50;
    }
  };

  const getOwnTariffPeople = () => {
    if (tariffType === "ownme" || tariffType === "ownmetest") {
      return "1-3";
    }
    if (tariffType === "ownfam" || tariffType === "ownfamtest") {
      return "4-6";
    }

    if (tariffType === "owncom" || tariffType === "owncomtest") {
      return "7-12";
    }
  };

  React.useEffect(() => {
    setTrafficChanged(
      remoteData?.data?.henchmens[activeHenchmenIndex]?.trafficLimit
    );
    setNamechanged(
      remoteData?.data?.henchmens[activeHenchmenIndex]?.description
    );
  }, [activeHenchmen, activeHenchmenIndex]);

  const getTestedDays = () => {
    const endDate = new Date(remoteData?.data?.endDate);
    const diffInMs = Math.abs(today - endDate);
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  };

  const handleClose = () => {
    setIsRemoteUsersHidden(true);
    setTimeout(setDoneMessageShow, 300, (state) => (state = false));
  };

  const handleNameChanged = (e) => {
    setNamechanged(e.target.value);
  };

  const handleTrafficChanged = (e) => {
    const hencmensLimit = remoteData?.data?.henchmens.reduce(
      (acc, henchman) => {
        if (henchman.trafficLimit !== 2000) {
          return acc + henchman.trafficLimit;
        }
        return acc;
      },
      0
    );

    const leftGB = remoteData?.data?.trafficTotal - hencmensLimit;

    if (e.target.value > leftGB) {
      setIsTrafficLimited(true);
      setTimeout(() => {
        setIsTrafficLimited(false);
      }, 3000);
    } else {
      setTrafficChanged(e.target.value);
    }
  };

  const handleCreateUser = () => {
    const isAvailableHechmens = henchmens.some(
      (item) => item.deletedUser === 1
    );

    if (isAvailableHechmens) {
      // setActiveHenchmen(henchmens[henchmens.findIndex((item) => item.deletedUser === 1)].id);
      setHenchmensOptions(
        henchmens[henchmens.findIndex((item) => item.deletedUser === 1)].id,
        { deletedUser: 0 }
      );
      setDoneMessageShow(true);
      setDoneMessage(
        translations[language].remote.remoteDoneCreated1 +
          translations[language].remote.remoteDoneCreated2
      );
    } else {
      setDoneMessageShow(true);
      setDoneMessage(translations[language].remote.remoteDoneNotCreated);
    }
  };

  const handleNameTrafficChanged = () => {
    if (currentUser.owner === 0) {
      setDirection(true);
      navigate("/tariffes/own");
    } else {
      setHenchmensOptions(activeHenchmen, {
        description: nameChanged,
        trafficLimit:
          trafficChanged === "сколько хочется" ? 2000 : trafficChanged,
      });
      setIsSettingsChanged(true);
      setTimeout(() => {
        setIsSettingsChanged(false);
      }, 3000);
    }
  };

  function getUsedGb() {
    if (tariffType === "ownme") {
      return 500 - remoteData.data.trafficTotal;
    }

    if (tariffType === "ownfam") {
      return 1000 - remoteData.data.trafficTotal;
    }

    if (tariffType === "owncom") {
      return 2000 - remoteData.data.trafficTotal;
    }

    if (remoteData?.data?.tariff?.includes("_test")) {
      return 50 - remoteData?.data?.trafficTotal;
    }
  }

  function getStartDate() {
    const months = parseInt(
      remoteData?.data?.tariff?.replace(/[a-zA-Z_]/g, "")
    );
    const endDate = new Date(remoteData?.data?.endDate);
    return parseTimestamp(endDate.setMonth(endDate.getMonth() - months));
  }

  function setHenchmensOptions(henchmenId, opts) {
    const index = henchmens.findIndex((henchman) => henchman.id === henchmenId);

    if (index === -1) {
      return henchmens;
    }

    const updatedHenchmens = [
      ...henchmens.slice(0, index),
      {
        ...henchmens[index],
        ...opts,
      },
      ...henchmens.slice(index + 1),
    ];

    const updatedHenchmensId = updatedHenchmens.map((user) => ({
      ...user,
      ownerId: currentUser.userId,
    }));
    setRemoteData({
      data: {
        tariff: remoteData?.data?.tariff,
        endDate: remoteData?.data?.endDate,
        linkDate: remoteData?.data?.linkDate,
        nextGB: remoteData?.data?.nextGB,
        trafficTotal: remoteData?.data?.trafficTotal,
        henchmens: updatedHenchmens,
      },
    });

    setRemoteOpts(currentUser.userId, activeHenchmen, updatedHenchmensId);
  }

  const renderusersList = (arr) => {
    return arr?.map((item, i) => {
      return (
        <div className="remote__users-container" key={i}>
          <li
            className={`remote__users-item ${
              activeHenchmenIndex === i && "active"
            } ${item.deletedUser === 1 && "hidden"}`}
            onClick={() => {
              setActiveHenchmenIndex(i);
              setActiveHenchmen(item.id);
              setNamechanged(item.description);
            }}
            key={i}
          >
            <input
              type="text"
              value={activeHenchmenIndex === i ? nameChanged : item.description}
              onChange={handleNameChanged}
              className={`remote__users-item-name  ${
                activeHenchmenIndex === i && "active"
              }`}
              onBlur={() =>
                setHenchmensOptions(activeHenchmen, {
                  description: nameChanged,
                  trafficLimit: trafficChanged,
                })
              }
            />
            <div className="remote__users-item-got">
              <input
                type={
                  item.remove === 1 || item.trafficLimit === 2000
                    ? "text"
                    : "number"
                }
                min={0}
                onBlur={() =>
                  setHenchmensOptions(activeHenchmen, {
                    description: nameChanged,
                    trafficLimit: trafficChanged,
                  })
                }
                value={
                  item.remove === 1
                    ? "ограничен"
                    : item.trafficLimit === 2000
                    ? "сколько хочется"
                    : activeHenchmenIndex === i
                    ? trafficChanged
                    : item.trafficLimit
                }
                onChange={handleTrafficChanged}
                className={`remote__users-item-got-input ${
                  (item.remove === 1 || item.trafficLimit === 2000) && "removed"
                } ${activeHenchmenIndex === i && "active"}`}
              />
              {/* <span className={`remote__users-item-got__gb ${item.remove === 1 && 'hidden'}`}>{translations[language].textTips.gb}</span> */}
            </div>
            <span className="remote__users-item-left">
              {Math.round(item.traffic)}
            </span>
            <div
              className={`remote__users-item-more ${
                activeHenchmenIndex === i && "active"
              }`}
              onClick={() => {
                setIsRemoteUsersHidden(false);
                setActiveHenchmen(item.id);
              }}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </li>
        </div>
      );
    });
  };

  const usersList = renderusersList(remoteData?.data?.henchmens);

  return (
    <motion.section
      className={`remote ${currentUser.owner === 0 && "disabled"} ${
        isRemoteInProgress && "inprogress"
      }`}
      initial={direction ? "fromLeft" : "fromRight"}
      animate={{
        x: 0,
        opacity: 1,
        transition: { duration: 0.2, delay: 0.2 },
      }}
      exit={direction ? "exitToRight" : "exitToLeft"}
      variants={directionVariants}
    >
      <div
        className={`remote__container ${isRemoteInProgress && "inprogress"}`}
      >
        <BurgerMenu
          color={`${isRemoteInProgress ? "var(--blue)" : "var(--white)"}`}
          remote={true}
        />
        <BackButton
          path="/"
          text={translations[language].appButton.mainMenu}
          currentClass={`${isRemoteInProgress ? "blue" : "white"}`}
          title={translations[language].textTips.remote}
        />
        {isRemoteInProgress ? (
          <>
            <div className="remote-inprogress__image-container">
              <img
                className="remote-inprogress__image"
                src={introImg}
                alt="happy face"
              />
            </div>
            <h1 className="remote-inprogress__title">
              {translations[language].remote.remoteInprogressTitle1} <br />
              <span className="remote-inprogress__title_colored">
                {" "}
                {translations[language].remote.remoteInprogressTitle2}
              </span>
            </h1>
            <p className="remote-inprogress__text">
              {translations[language].remote.remoteInprogressText}
            </p>
            <div className="remote-inprogress__button-container">
              <AppButton
                text={translations[language].appButton.mainMenu}
                currentClass="app-button border-blue secondary blue"
                handler={() => navigate("/")}
              />
              <AppButton
                currentClass="app-button primary white bg-blue margin-top"
                text={translations[language].appButton.gotIt}
                handler={() => tg.close()}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className={`remote__speed ${
                currentUser.owner === 0 && "no-own"
              } ${isTrafficLimited && "limited-error"}`}
            >
              {isTrafficLimited ? (
                <>{translations[language].remote.remoteTrafficLimited}</>
              ) : currentUser.owner === 0 ? (
                <>
                  {translations[language].remote.remoteNoOwn}
                  <span
                    className="remote__link"
                    onClick={() => {
                      setDirection(true);
                      navigate("/tariffes/own");
                    }}
                  >
                    {translations[language].remote.remoteNoOwnLink}
                  </span>
                </>
              ) : isNeverOwn ? (
                <>
                  {translations[language].tariffOwn.ownSpeed1}
                  <br />
                  {translations[language].tariffOwn.ownSpeed2}
                </>
              ) : isOwnTariff ? (
                <>
                  {translations[language].tariffOwn.ownWidget1}
                  {","}
                  {remoteData?.data?.endDate >= today
                    ? translations[language].tariffOwn.ownWidget5 +
                      parseTimestamp(remoteData?.data?.endDate)
                    : null}
                  <br />
                  {remoteData?.data?.endDate > today
                    ? remoteData?.data?.trafficTotal > 0
                      ? translations[language].tariffOwn.ownWidget7 +
                        (remoteData?.data?.nextGB
                          ? parseTimestamp(remoteData?.data?.nextGB)
                          : parseTimestamp(remoteData?.data?.endDate)) +
                        translations[language].tariffOwn.ownWidget8 +
                        remoteData?.data?.trafficTotal +
                        translations[language].textTips.gb
                      : translations[language].tariffOwn.ownWidget9 +
                        getOwnTariffTypeGb() +
                        translations[language].textTips.gb +
                        " " +
                        (remoteData?.data?.nextGB
                          ? parseTimestamp(remoteData?.data?.nextGB)
                          : parseTimestamp(remoteData?.data?.endDate))
                    : null}
                </>
              ) : isTestOwnTariff && remoteData?.data?.endDate >= today ? (
                <>
                  {translations[language].tariffOwn.ownWidget1}
                  <br />
                  {translations[language].tariffOwn.ownWidget2}
                  {getTestedDays()}
                  {translations[language].tariffOwn.ownWidget10}
                </>
              ) : (
                <>
                  {translations[language].tariffOwn.ownWidget1}
                  <br />
                  {translations[language].tariffOwn.ownWidget4}
                </>
              )}
            </div>

            <div className={`remote__info`}>
              <div className="remote__info-gb">
                <div className="remote__info-gb-left">
                  <span
                    className={`remote__info-title ${
                      currentUser.owner === 0 && "disabled"
                    }`}
                  >
                    {currentUser.owner === 0
                      ? "300"
                      : Math.round(remoteData?.data?.trafficTotal)}{" "}
                    {translations[language].textTips.gb}
                  </span>
                  <span
                    className={`remote__info-subtitle ${
                      currentUser.owner === 0 && "disabled"
                    }`}
                  >
                    {translations[language].remote.remoteLeft}
                  </span>
                </div>
                <div className="remote__info-gb-used">
                  <span className="remote__info-title">
                    {currentUser.owner === 0 ? "200" : Math.round(getUsedGb())}{" "}
                    {translations[language].textTips.gb}
                  </span>
                  <span className="remote__info-subtitle">
                    {translations[language].remote.remoteUsed}
                  </span>
                </div>
              </div>

              <div className="remote__info-users">
                <span className="remote__info-title-big">
                  {currentUser.owner === 0 ? "500" : getOwnTariffTypeGb()}
                </span>
                <span className="remote__info-subtitle">
                  {translations[language].remote.remoteTotal1}
                  <br />
                  {translations[language].remote.remoteTotal3}
                  {currentUser.owner === 0
                    ? "8.4.2023"
                    : parseTimestamp(remoteData?.data?.linkDate)}
                  {translations[language].remote.remoteTotal4}
                  {currentUser.owner === 0
                    ? "5.5.2023"
                    : remoteData?.data?.nextGB
                    ? parseTimestamp(remoteData?.data?.nextGB)
                    : parseTimestamp(remoteData?.data?.endDate)}
                  <br />
                  {translations[language].remote.remoteTotal5}
                  {currentUser.owner === 0 ? "1-3" : getOwnTariffPeople()}
                  {translations[language].remote.remoteTotal6}
                </span>
              </div>
            </div>

            <div
              className={`remote__users ${
                currentUser.owner === 0 && "margin-bottom"
              }`}
            >
              <div className="remote__users-titles">
                <span className="remote__users-title">
                  <span>
                    {translations[language].remote.remoteUsers1Title1}
                  </span>
                  <span>
                    {translations[language].remote.remoteUsers1Title2}
                  </span>
                </span>
                <span className="remote__users-title">
                  <span>
                    {translations[language].remote.remoteUsers2Title1}
                  </span>
                  <span>
                    {translations[language].remote.remoteUsers2Title2}
                  </span>
                </span>
                <span className="remote__users-title">
                  <span>
                    {translations[language].remote.remoteUsers3Title1}
                  </span>
                  <span>
                    {translations[language].remote.remoteUsers3Title2}
                  </span>
                </span>
              </div>
              <ul className="remote__users-list">
                {currentUser.owner === 0 ? (
                  <>
                    <li className={`remote__users-item`}>
                      <input
                        type="text"
                        value={"Пользователь"}
                        className={`remote__users-item-name disabled`}
                      />
                      <div className="remote__users-item-got">
                        <input
                          type="number"
                          value={10}
                          className={`remote__users-item-got-input disabled`}
                        />
                      </div>
                      <span className="remote__users-item-left">{5}</span>
                      <div className={`remote__users-item-more`}>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </li>
                    <li className={`remote__users-item`}>
                      <input
                        type="text"
                        value={"Пользователь"}
                        className={`remote__users-item-name disabled`}
                      />
                      <div className="remote__users-item-got">
                        <input
                          type="number"
                          value={10}
                          className={`remote__users-item-got-input disabled`}
                        />
                      </div>
                      <span className="remote__users-item-left">{5}</span>
                      <div className={`remote__users-item-more`}>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </li>
                    <li className={`remote__users-item`}>
                      <input
                        type="text"
                        value={"Пользователь"}
                        className={`remote__users-item-name disabled`}
                      />
                      <div className="remote__users-item-got">
                        <input
                          type="number"
                          value={10}
                          className={`remote__users-item-got-input disabled`}
                        />
                      </div>
                      <span className="remote__users-item-left">{5}</span>
                      <div className={`remote__users-item-more`}>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </li>
                  </>
                ) : (
                  usersList
                )}
              </ul>
            </div>

            <RemotePopup
              isHidden={isRemoteUsersHidden}
              setIsHidden={setIsRemoteUsersHidden}
              setDoneShow={setDoneMessageShow}
            >
              {doneMessageShow ? (
                <>
                  <span className="remote__popup-message">{doneMessage}</span>
                  <AppButton
                    currentClass="app-button orange bg-white margin-top"
                    text="OK"
                    handler={handleClose}
                  />
                </>
              ) : (
                <>
                  <AppButton
                    handler={() => {
                      getHenchmenLink(activeHenchmen);
                      setDoneMessageShow(true);
                      setDoneMessage(
                        translations[language].remote.remoteDoneLink1 +
                          henchmens.find(
                            (hechmen) => hechmen.id === activeHenchmen
                          ).description +
                          translations[language].remote.remoteDoneLink2
                      );
                    }}
                    text={translations[language].remote.remoteBtnSendLink}
                    currentClass="app-button border-white bg-orange white small-text"
                  />
                  <AppButton
                    handler={() => {
                      setHenchmensOptions(activeHenchmen, {
                        trafficLimit: 2000,
                      });
                      setDoneMessageShow(true);
                      setDoneMessage(
                        translations[language].remote.remoteDoneLet1 +
                          henchmens.find(
                            (hechmen) => hechmen.id === activeHenchmen
                          ).description +
                          translations[language].remote.remoteDoneLet2
                      );
                    }}
                    text={translations[language].remote.remoteBtnLet}
                    currentClass="app-button border-white bg-orange white small-text margin-top"
                  />
                  <AppButton
                    handler={() => {
                      setHenchmensOptions(
                        activeHenchmen,
                        henchmens.find(
                          (hechmen) => hechmen.id === activeHenchmen
                        ).remove === 1
                          ? { remove: 0 }
                          : { remove: 1 }
                      );
                      setDoneMessageShow(true);
                      setDoneMessage(
                        remoteData?.data?.henchmens?.find(
                          (hechmen) => hechmen.id === activeHenchmen
                        )?.remove === 1
                          ? translations[language].remote.remoteDoneStart1 +
                              remoteData?.data?.henchmens?.find(
                                (hechmen) => hechmen.id === activeHenchmen
                              )?.description +
                              translations[language].remote.remoteDoneStart2
                          : translations[language].remote.remoteDoneStop1 +
                              remoteData?.data?.henchmens?.find(
                                (hechmen) => hechmen.id === activeHenchmen
                              )?.description +
                              translations[language].remote.remoteDoneStop2
                      );
                    }}
                    text={
                      remoteData?.data?.henchmens?.find(
                        (hechmen) => hechmen.id === activeHenchmen
                      )?.remove === 1
                        ? translations[language].remote.remoteBtnStart
                        : translations[language].remote.remoteBtnStop
                    }
                    currentClass="app-button border-white bg-orange white small-text margin-top"
                  />
                  <AppButton
                    handler={() => {
                      setHenchmensOptions(activeHenchmen, { deletedUser: 1 });
                      setDoneMessageShow(true);
                      setDoneMessage(
                        translations[language].remote.remoteDoneDelete1 +
                          remoteData?.data?.henchmens.find(
                            (hechmen) => hechmen.id === activeHenchmen
                          ).description +
                          translations[language].remote.remoteDoneDelete2
                      );
                    }}
                    text={translations[language].remote.remoteBtnDelete}
                    currentClass="app-button border-white bg-orange white small-text margin-top"
                  />
                  <AppButton
                    handler={() => handleCreateUser()}
                    text={translations[language].remote.remoteBtnAddUser}
                    currentClass="app-button border-white bg-orange white small-text margin-top"
                  />

                  {/* <div className="remote__popup-info">
                                {getMonthOrTest 
                                ? (<>
                                    {translations[language].remote.remoteInfoPopup1
                                     + getOwnTariffPeople()
                                     + translations[language].remote.remoteInfoPopup2
                                     + translations[language].remote.remoteInfoPopup3}
                                    </>)
                                : (<>
                                    {translations[language].remote.remoteInfoPopup1 
                                    + getOwnTariffPeople()
                                    + translations[language].remote.remoteInfoPopup2
                                    + translations[language].remote.remoteInfoPopup4
                                    + parseTimestamp(remoteData?.data?.endDate)
                                    + translations[language].remote.remoteInfoPopup5
                                    + getOwnTariffTypeGb()
                                    + translations[language].remote.remoteInfoPopup6}
                                    </>)}
                            </div> */}
                </>
              )}
            </RemotePopup>

            {currentUser.owner === 0 ? null : (
              <div className="remote__info-connection">
                {translations[language].tariffOwn.ownInfoConnection}
              </div>
            )}

            <div className="remote__settings-button">
              {isSettingsChanged && (
                <div className="remote__settings-button__popup">
                  <span className="remote__settings-button__popup-text">
                    {translations[language].remote.remoteSettingsChanged}
                  </span>
                </div>
              )}
              <AppButton
                text={
                  currentUser.owner === 0
                    ? currentUser.own_tested
                      ? translations[language].remote.remoteBuyOwnBtn
                      : translations[language].remote.remoteNoOwnBtn
                    : translations[language].remote.remoteUseOpts
                }
                currentClass="app-button bg-white orange margin-top"
                handler={handleNameTrafficChanged}
              />
            </div>

            <Popup
              title={translations[language].remote.remoteInstruction}
              isHidden={isRemotePopupHidden}
              handleHide={setIsRemotePopupHidden}
              currentClass={`popup-remote ${
                currentUser.owner === 0 && "disabled"
              }`}
            >
              {
                <>
                  <div className="tariffes__popup-overlay" />
                  <div className="tariffes__popup-container">
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText1}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText2}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText3}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText4}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText5}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText6}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText7}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText8}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText9}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText10}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText11}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText12}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText13}
                    </p>
                    <p className="referral__popup-text">
                      {translations[language].remote.remotePopupText14}
                    </p>
                  </div>
                  <AppButton
                    text={translations[language].appButton.gotIt}
                    currentClass={`margin-top bg-orange primary white`}
                    handler={() => setIsRemotePopupHidden(true)}
                  />
                </>
              }
            </Popup>
          </>
        )}
      </div>
    </motion.section>
  );
}

export default Remote;
